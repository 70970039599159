import Head from 'next/head'
import Header from './header'
import Footer from './footer'

const schema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Batteries for Transportation",
            "item": "https://www.eastpenncanada.com/transportation"
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "Material Handling Batteries",
            "item": "https://www.eastpenncanada.com/material-handling-batteries"
        },
        {
            "@type": "ListItem",
            "position": 3,
            "name": "Motorcycle & PowerSport Batteries",
            "item": "https://www.eastpenncanada.com/motorcycle-and-powersport-batteries"
        },
        {
            "@type": "ListItem",
            "position": 4,
            "name": "Renewable Energy Power Solutions",
            "item": "https://www.eastpenncanada.com/renewable-energy-power-solutions"
        },
        {
            "@type": "ListItem",
            "position": 5,
            "name": "Backup Power",
            "item": "https://www.eastpenncanada.com/backup-power"
        },
        {
            "@type": "ListItem",
            "position": 6,
            "name": "Consumer Batteries",
            "item": "https://www.eastpenncanada.com/consumer-batteries"
        },
        {
            "@type": "ListItem",
            "position": 7,
            "name": "Material Handling Battery and Charger Service",
            "item": "https://www.eastpenncanada.com/material-handling-battery-and-charger-service"
        }
    ]
}

export default function Layout({ children, pageTitle, metaDescription, ogImage }) {
    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

                <title>{pageTitle}</title>

                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:type" content="article" />
                <meta property="og:image" content={ogImage} />

                <meta name="google-site-verification" content="5EpTFdZ5De_fBcVRVvG4duGaFkOLDIqUQKYbKloIcf0" />
                <meta name="msvalidate.01" content="DC5BA6A27DEC789154A717AA36018347" />

                <link rel="shortcut icon" href="/img/favicon.ico" type="image/x-icon" />
                <link rel="dns-prefetch stylesheet" href="https://use.typekit.net/xhu4har.css" />

                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
            </Head>

            <div className='max-w-2560 mx-auto'>
                <Header />

                <main id={'content'}>
                    {children}
                </main>

                <Footer />
            </div>
        </>
    )
}