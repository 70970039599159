import { useState } from "react";
import { useRouter } from "next/router";
import classnames from "classnames";
import AsyncSelect from 'react-select/async';
import axios from "axios";

const customStyles = {
  container: (provided) => ({
    ...provided,
    margin: "auto",
    width: 250,
  }),
  placeholder: (styles) => ({
    ...styles,
    fontFamily: "futura-pt, sans-serif",
    fontSize: "22px",
    fontWeight: "bold",
    color: "#5C5C5C",
    textTransform: "uppercase",
    textAlign: "center",
    width: "100%",
    paddingRight: "20px",
  }),
  option: (styles) => ({
    ...styles,
    fontFamily: "futura-pt, sans-serif",
    fontSize: "20px",
    fontWeight: "400",
    color: "#5C5C5C",
  }),
};

const fetchPageData = async (search, lang) => {
  const { data } = await axios.get("/api/pages", {
    params: {
      search: search,
      lang: lang,
    }
  });
  return { data };
};

const SearchComponent = ({ headerHover, lang = 'en' }) => {
  const [isHover, setHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const router = useRouter();

  const CustomDropdownIndicator = (props) => (
    <span className="select-mglass">&#9906;</span>
  );

  function handleChange(e) {
    router.push(`/${e.value}`)
    setIsOpen(false)
    setHover(false)
  }

  function searchCallback(data) {
    return data
  }

  const searchPages = search =>
      new Promise(resolve => {
        fetchPageData(search, lang).then(function(res) {
          resolve(searchCallback(res.data))
        })
      })

  return (
    <div
      className="flex relative items-center"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <AsyncSelect
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        className={classnames("search max-w-full left-auto right-0 bg-white border-0 rounded-3xl h-full outline-none z-10 font-futura font-bold text-left text-16 text-grey over", {"search-open": isHover || isOpen})}
        classNamePrefix="select"
        isDisabled={false}
        isClearable={false}
        isSearchable={true}
        indicatorSeparator={null}
        name="page"
        styles={customStyles}
        loadOptions={searchPages}
        cacheOptions
        defaultOptions
        onFocus={(e) => setIsOpen(true)}
        onBlur={(e) => setIsOpen(false)}
        onInputChange={(e) => setSearch(e)}
        onChange={handleChange}
        placeholder={lang === 'en' ? "Searching for..." : 'Recherche...'}
        id="searchright"
        instanceId="searchright"
      />
      <label
        className="absolute text-35 z-20 leading-50 right-10 top-0"
        htmlFor="searchright"
      >
        <span
          className={classnames(
            "inline-block pointer-events-none transform rotate-45",
            { "text-black": headerHover },
            { "text-white": !isHover && !headerHover },
            { "text-primary": isHover || search !== "" }
          )}
        >
          &#9906;
        </span>
      </label>
    </div>
  );
};

export default SearchComponent;
