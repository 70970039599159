import { useEffect, useState } from "react";
import classnames from "classnames";
import axios from "axios";
import AsyncSelect from 'react-select/async';
import { useRouter } from "next/router";
import NextLink from 'next/link'
import Image from 'next/image'
import scrollTop from '../../public/img/scroll-top.png'
import logo from '../../public/img/logo.png'
import linkedIn from '../../public/img/footer/linkedin.png'
import Instagram from '../../public/img/footer/instagram.svg'
import Facebook from '../../public/img/footer/facebook.svg'
import Link from "../types/link";

const customStyles = {
    container: (provided) => ({
        ...provided,
        margin: "auto",
        width: '100%',
    }),
    control: (styles) => ({
        ...styles,
        background: 'transparent',
        padding: 0,
    }),
    valueContainer: (styles) => ({
        ...styles,
        padding: 0,
    }),
    placeholder: (styles) => ({
        ...styles,
        fontFamily: "futura-pt, sans-serif",
        fontSize: "22px",
        fontWeight: "bold",
        color: "#FFFFFF",
        textTransform: "uppercase",
        textAlign: "left",
        width: "100%",
        paddingRight: "30px",
        "@media only screen and (min-width: 768px)": {
            fontSize: '30px',
        },
    }),
    option: (styles) => ({
        ...styles,
        fontFamily: "futura-pt, sans-serif",
        fontSize: "20px",
        fontWeight: "400",
        color: "#5C5C5C",
    }),
};

const fetchFooterNavigationData = async(lang) => {
    const { data } = await axios.get("/api/navigation/footer", {
        params: {
            lang: lang,
        }
    });
    return { data };
};

const fetchPageData = async(search, lang) => {
    const { data } = await axios.get("/api/pages", {
        params: {
            search: search,
            lang: lang,
        }
    });
    return { data };
};

const Footer = () => {
    const router = useRouter();
    const lang = router.locale
    const [footerNav, setFooterNav] = useState({});
    const CustomDropdownIndicator = (props) => (
        <span className="select-mglass">&#9906;</span>
    );

    const MenuColumn = ({ title, children, link }) => {
        const [isOpen, setOpen] = useState(false);
        
        return (
            <div
                className="col relative p-20 lg:p-0 border lg:border-0 border-solid border-white text-22 md:text-32 min-w-220 lg:mr-10 xl:mr-20 2xl:mr-40 last:mr-0">
                <div
                    className={classnames(
                        "accordion text-22 md:text-32 flex items-center lg:hidden justify-between md:text-32 text-primary font-bold font-futura cursor-pointer")}
                    onClick={() => setOpen(!isOpen)}
                >
                    {title}
                    <span className={classnames("chevron bottom text-primary", { "open": isOpen })}></span>
                </div>
                <div
                    className={classnames(
                        "accordion text-22 md:text-32 hidden items-center lg:flex justify-between md:text-32 text-primary font-bold font-futura cursor-pointer mb-10")}
                >
                    <Link link={link} classes='text-22 md:text-32 text-primary font-bold font-futura'>
                        {title}
                    </Link>
                </div>
                <div className={classnames("text-22 md:text-32 font-light lg:block mt-20", { hidden: !isOpen })}>
                    {children}
                </div>
            </div>
        );
    };

    const MenuColumn1 = ({ title, link }) => {
        
        return (
            <div
                className="col relative p-20 lg:p-0 border lg:border-0 lg:hidden border-solid border-white text-22 md:text-32 min-w-220 lg:mr-10 xl:mr-20 2xl:mr-40 last:mr-0">
                <div
                    className={classnames(
                        "accordion text-22 md:text-32 flex items-center justify-between md:text-32 text-primary font-bold font-futura cursor-pointer")}
                >
                    <Link link={link} classes='text-22 md:text-32 text-primary font-bold font-futura'>
                        {title}
                    </Link>
                </div>
            </div>
        );
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        // Scroll to top button
        document
            .getElementById("scrollToTop")
            .addEventListener("click", scrollToTop);
    });

    useEffect(() => {
        const fetchData = async() => {
            const { data: data } = await fetchFooterNavigationData(lang);
            setFooterNav(data);
        };

        fetchData();
    }, [lang]);

    function searchCallback(data) {
        return data
    }

    const searchPages = search =>
        new Promise(resolve => {
            fetchPageData(search, lang).then(function(res) {
                resolve(searchCallback(res.data))
            })
        })
        const { pathname, asPath, query } = router
    return (
        <footer id='footer' className="footer bg-black py-20 px-20 sm:px-50 relative">
            <button id="scrollToTop" className="w-50 h-50 absolute right-0 bottom-11">
                <Image src={scrollTop} alt='Scroll to Top'/>
            </button>

            <div className="col">
                <div className="flex justify-center items-center text-center my-50 lg:mt-70 lg:mb-0">
                    <NextLink href={lang === 'fr' ? '/fr/' : '/'}>
                        <a className='max-w-365 lg:max-w-280 my-10 lg:my-0'>
                            <Image src={logo} alt='Eastpenn Canada Logo'/>
                        </a>
                    </NextLink>
                </div>
                <div
                    className="row footer__menu flex justify-center max-w-1024 w-full m-auto px-0 lg:pt-100 lg:pb-50 flex-col lg:flex-row border lg:border-0 border-solid border-white">
                    <div
                        className="col relative p-20 lg:p-0 border lg:border-0 border-solid border-white text-22 md:text-32 lg:hidden block">
                        <AsyncSelect
                            components={{ DropdownIndicator: CustomDropdownIndicator }}
                            className="basic-single max-w-full"
                            classNamePrefix="select"
                            isDisabled={false}
                            isClearable={false}
                            isSearchable={true}
                            indicatorSeparator={null}
                            name="page"
                            styles={customStyles}
                            loadOptions={searchPages}
                            cacheOptions
                            defaultOptions
                            onChange={(e) => router.push(`/${e.value}`)}
                            placeholder={lang === 'fr' ? 'CHERCHER' : "SEARCH"}
                            instanceId='footer-search'
                        />
                    </div>
                    <div
                        className="col relative p-20 lg:p-0 border lg:border-0 border-solid border-white text-22 md:text-32 lg:hidden block">
                        <NextLink href='tel:1-800-668-9303'>
                            <a className="block text-left font-futura text-22 md:text-30 font-bold text-white call pr-30">
                                {lang === 'fr' ? 'PARLER À UN AGENT' : 'SPEAK TO AN AGENT'}
                            </a>
                        </NextLink>
                    </div>
                    {footerNav &&
                    footerNav.navigation &&
                    footerNav.navigation.mainNavLinks &&
                    footerNav.navigation.mainNavLinks.length > 0 &&
                    footerNav.navigation.mainNavLinks.map((section) => (
                        section && section.secondarySection && section.secondarySection.length > 0 ?
                        <MenuColumn key={section._key} title={section.link.text} link={section.link}>
                                <ul className="list-none p-0">
                                    {section.secondarySection.map((link) => (
                                        <li className="mb-10" key={link._key}>
                                            <Link link={link} classes='text-22 leading-30 font-futura text-white no-underline hover:text-primary'>
                                                {link.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                        </MenuColumn> :
                        <MenuColumn1 key={section._key} title={section.link.text} link={section.link}>
                        </MenuColumn1>
                    ))}
                </div>
                <div className="footer-access row flex flex-row justify-between lg:justify-center mb-50 lg:mb-0 items-center pt-40">
                    <div className="my-10 xs:my-0">
                         <NextLink href={lang === 'fr' ? '/' : '/fr/'} locale={lang === 'fr' ? 'en' : 'fr'}>
                            <a
                                className="font-futura text-18 sm:text-22 mx-10 sm:mx-35 my-0 text-white no-underline font-semibold leading-25 hover:text-primary"
                            >
                                {lang === 'fr' ? (
                                    <>
                                        English
                                    </>
                                ) : (
                                    <>
                                        Français
                                    </>
                                )}
                            </a>
                        </NextLink>
                    </div>
                    <div>
                        {footerNav.navigation && footerNav.navigation.linkedin && (
                            <a
                                className="w-40 sm:w-60 lg:w-30 font-futura text-22 md:text-32 mx-10 sm:mx-35 my-0 text-white no-underline font-semibold leading-25"
                                href={footerNav.navigation.linkedin}
                                target='_blank'
                                rel="noreferrer"
                            >
                                <Image src={linkedIn} alt='LinkedIn' width={30} height={30} />
                            </a>
                        )}
                        {footerNav.navigation && footerNav.navigation.instagram && (
                            <a
                                className="w-40 sm:w-60 lg:w-30 font-futura text-22 md:text-32 mx-10 sm:mx-35 my-0 text-white no-underline font-semibold leading-25"
                                href={footerNav.navigation.instagram}
                                target='_blank'
                                rel="noreferrer"
                            >
                                <Image src={Instagram} alt='Instagram' width={30} height={30} />
                            </a>
                        )}
                        {footerNav.navigation && footerNav.navigation.facebook && (
                            <a
                                className="w-40 sm:w-60 lg:w-30 font-futura text-22 md:text-32 mx-10 sm:mx-35 my-0 text-white no-underline font-semibold leading-25"
                                href={footerNav.navigation.facebook}
                                target='_blank'
                                rel="noreferrer"
                            >
                                <Image src={Facebook} alt='Facebook' width={30} height={30} />
                            </a>
                        )}
                    </div>
                </div>
                <div
                    className="row footer__bottom flex flex-col lg:flex-row items-baseline lg:items-center justify-between my-0 lg:m-50">
                    <ul className="text-white lg:text-primary text-35 flex flex-wrap items-center">
                        {footerNav &&
                        footerNav.navigation &&
                        footerNav.navigation.bottomNavLinks &&
                        footerNav.navigation.bottomNavLinks.length > 0 &&
                        footerNav.navigation.bottomNavLinks.map((link, k) => (
                            <li key={link._key} className="font-futura text-20 text-white lg:text-primary no-underline list-disc first:list-none ml-30 first:ml-0">
                                <Link link={link} classes='text-white hover:text-primary'>
                                    {link.text}
                                </Link>
                            </li>
                        ))}
                    </ul>

                    {footerNav.navigation && footerNav.navigation.copyright && (
                        <div className="font-futura text-20 text-white mt-20 lg:mt-0 ml-10 sm:ml-0 sm:mt-30 lg:ml-50">
                            {footerNav.navigation.copyright}
                        </div>
                    )}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
