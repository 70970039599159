import NextLink from 'next/link'

// Renders links from our Sanity link object to handle internal/external links easily
export default function Link({ link, classes = '', children, ...props }) {
    if(link && link.linkType && (link.internalLink || link.externalLink || link.fileLink)) {
        let href = ''
        const openInNewTab = link.openInNewTab ? '_blank' : null

        if(link.linkType === 'internal') {
            if(link.internalLink._type === "blog.post") {
                href = '/blog/'
            }

            if(link.internalLink.slug){
                href += link.internalLink.slug.current !== '/' ? `${link.internalLink.slug.current}` : '/'
            }

            if(href.charAt(0) !== '/') {
                href = '/' + href
            }
        } else if(link.linkType === 'external') {
            href = link.externalLink
        } else if(link.linkType === 'file') {
            href = link.fileLink.asset.url
        }

        return (
            <NextLink href={href}>
                <a
                    target={openInNewTab}
                    rel={openInNewTab ? 'noreferrer' : ''}
                    className={classes}
                    {...props}
                >
                    {children ? children : link.text}
                </a>
            </NextLink>
        )
    } else {
        return (
            <>
                {children}
            </>
        )
    }
}