import React from "react";
import NextLink from 'next/link'
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import classnames from "classnames";
import axios from "axios";
import SearchComponent from "./SearchComponent";
import Link from "../types/link";

const fetchHeaderNavigationData = async(lang) => {
    const { data } = await axios.get("/api/navigation/header", {
        params: {
            lang: lang,
        }
    });
    return { data };
};

const MenuColumn = ({ title, children }) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <div className="col min-w-220">
            <div
                className={classnames("accordion flex items-center leading:60 xs:leading-90 text-35 md:text-50 font-medium font-futura cursor-pointer whitespace-nowrap", { "text-white": !isOpen }, { "text-primary": isOpen })}
                onClick={() => setOpen(!isOpen)}>
                {title}
                <button className={classnames({ "open text-primary": isOpen }, { "text-white": !isOpen })}>
                    <span className={classnames({ "bg-primary": isOpen }, { "bg-white": !isOpen })}/>
                    <span className={classnames({ "bg-primary": isOpen }, { "bg-white": !isOpen })}/>
                </button>
            </div>
            <div className={classnames("panel", { "hidden": !isOpen })}>
                {children}
            </div>
        </div>
    )
}

const MenuSubColum = ({ title, link, children }) => {
    const [isOpen, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!isOpen);
        if(link) {
            // router.push(`/${target.slug.current}`);
        }
    }

    return (
        <li>
            <a
                onClick={handleClick}
                className={classnames("accordion sub-column", { "text-white": !isOpen }, { "text-primary": isOpen })}
            >
                {title}
                {children && <button className={classnames({ "open text-primary": isOpen }, { "text-white": !isOpen })}>
                    <span className={classnames({ "bg-primary": isOpen }, { "bg-white": !isOpen })}/>
                    <span className={classnames({ "bg-primary": isOpen }, { "bg-white": !isOpen })}/>
                </button>}
            </a>
            {isOpen && children}
        </li>
    );
}


export default function Header() {
    const [headerNav, setHeaderNav] = useState({});
    const [isHover, setHover] = useState(false);
    const router = useRouter();
    const lang = router.locale

    useEffect(() => {
        const fetchData = async() => {
            const { data: data } = await fetchHeaderNavigationData(lang);
            setHeaderNav(data);
        };

        fetchData();
    }, [lang]);

    const onHover = () => {
        setHover(true);
    }

    const onOut = () => {
        setHover(false);
    }
    const handleClickFR = () => {
        if(lang === 'fr') {
            router.push(`/`, '/', { locale: 'en' });
        } else {
            router.push(`/fr/`, '/fr/', { locale: 'fr' });
        }
    }

    const linkClick = () => {
        document.getElementById('menu__toggle').checked = false
    }

    return (
        <div className='relative'>
            <div className="ie-only">
                This browser is not supported. We recommend the latest version of
                Safari, Firefox, Chrome, or Edge to use our website. Thank you.
            </div>
            <header onMouseOver={onHover} onMouseOut={onOut}
                    className="header flex items-center bg-black hover:bg-white justify-between px-10 py-30 sm:px-30">
                <div className="absolute flex items-center justify-end z-100">
                    <div>
                        <input className="w-auto" id="menu__toggle" type="checkbox"/>
                        <label
                            className="menu__btn flex items-center cursor-pointer w-30 h-30 left-20"
                            htmlFor="menu__toggle"
                        >
                            <div className="cursor-pointer w-50 h-50 absolute"/>
                            <span className="cursor-pointer w-30 xs:w-30"/>
                        </label>

                        <div
                            className="menu__box flex fixed overflow-y-auto overflow-x-hidden top-0 z-100 w-full h-full m-0 px-20 xs:px-80 py-50 xs:py-100 list-none bg-black">
                            {headerNav && headerNav.navigation && headerNav.navigation.mainNavLinks && headerNav.navigation.mainNavLinks.length > 0 &&
                            headerNav.navigation.mainNavLinks.map((mainNavLinks) => (
                                <MenuColumn key={mainNavLinks._key} title={mainNavLinks.title}>
                                    {mainNavLinks && mainNavLinks.secondarySection && mainNavLinks.secondarySection.length > 0 &&
                                    <ul>
                                        {mainNavLinks.secondarySection.map((secondarySection) => (
                                            <React.Fragment key={secondarySection._key}>
                                                {secondarySection.tertiarySection && secondarySection.tertiarySection.length > 0 ? (
                                                    <MenuSubColum title={secondarySection.link.text}
                                                                  link={secondarySection.link}>
                                                        <ul className="panel mb-20">
                                                            {secondarySection.tertiarySection.map(child => (
                                                                <li key={child._key}>
                                                                    <Link link={child.link} onClick={linkClick}>
                                                                        {child.link.text}
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </MenuSubColum>
                                                ) : (
                                                    <Link link={secondarySection.link} onClick={linkClick}
                                                          classes='block font-futura text-24 leading-30 text-white hover:text-primary mb-20'>
                                                        {secondarySection.link.text}
                                                    </Link>
                                                )}
                                            </React.Fragment>
                                        ))
                                        }
                                    </ul>
                                    }
                                </MenuColumn>
                            ))
                            }

                            <a
                                href={lang === 'fr' ? 'https://can231.dayforcehcm.com/CandidatePortal/fr-CA/eastpenn' : 'https://can231.dayforcehcm.com/CandidatePortal/en-US/eastpenn'}
                                target='_blank'
                                rel='noreferrer'
                                aria-label='Link opens in a new tab.'
                                className="lg:hidden bg-primary hover:bg-white border-2 border-primary text-white hover:text-primary text-16 md:text-20 leading-20 md:leading-24 font-medium text-center py-10 px-20 md:px-30 mt-25 ml-20 mr-auto rounded-full">
                                {lang === 'fr' ? 'Rejoignez notre équipe' : 'Join Our Team'}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="header__center w-full text-center">
                    <NextLink
                        href={lang === 'fr' ? '/fr/' : '/'}
                    >
                        <a className="logo"
                           aria-label="East Penn Logo"/>
                    </NextLink>
                </div>

                <a
                    href={lang === 'fr' ? 'https://can231.dayforcehcm.com/CandidatePortal/fr-CA/eastpenn' : 'https://can231.dayforcehcm.com/CandidatePortal/en-US/eastpenn'}
                    target='_blank'
                    rel='noreferrer'
                    aria-label='Link opens in a new tab.'
                    className="hidden lg:block absolute right-200 bg-primary hover:bg-white border-2 border-primary text-white hover:text-primary text-16 leading-20 font-medium text-center py-10 px-20 rounded-full">
                    {lang === 'fr' ? 'Rejoignez notre équipe' : 'Join Our Team'}
                </a>

                <span onClick={handleClickFR}
                      className="header__lang cursor-pointer font-futura absolute right-60 sm:right-100">
                    {lang === 'fr' ? (
                        <>
                            <span className='hidden md:inline'>
                                ENGLISH
                            </span>
                            <span className='md:hidden'>
                                EN
                            </span>
                        </>
                    ) : (
                        <>
                            <span className='hidden md:inline'>
                                FRANÇAIS
                            </span>
                            <span className='md:hidden'>
                                FR
                            </span>
                        </>
                    )}
                </span>
            </header>

            <aside className="flex absolute items-center justify-end right-20 top-35 sm:top-55 z-100">
                <SearchComponent headerHover={isHover} lang={lang}/>
            </aside>
        </div>
    );
}